export const sport_icons = {
    "all": 0xf2a3,
    "citybike": 0xf2a4,
    "climbing": 0xf2a5,
    "crosscountry": 0xf2a6,
    "downhillbike": 0xf2a7,
    "hike": 0xf2a8,
    "jogging": 0xf2a9,
    "mountaineering": 0xf2aa,
    "mountaineering_advanced": 0xf2ab,
    "mountaineering_easy": 0xf2ac,
    "mtb": 0xf2ad,
    "e_mtb": 0xf2ad,
    "mtb_advanced": 0xf2ae,
    "mtb_easy": 0xf2af,
    "nordic": 0xf2b0,
    "nordicwalking": 0xf2b1,
    "other": 0xf2b2,
    "racebike": 0xf2b3,
    "skaten": 0xf2b4,
    "skialpin": 0xf2b5,
    "skitour": 0xf2b6,
    "sled": 0xf2b7,
    "snowboard": 0xf2b8,
    "snowshoe": 0xf2b9,
    "touringbicycle": 0xf2ba,
    "unicycle": 0xf2bb,
    "...": 0xf23a,
}


async function get(url) {
    const response = await fetch(url);
    return await response.json();
}

export async function getUser(komoot_id) {
    if (komoot_id == "") return undefined
    var url = "https://api.komoot.de/v007/users/"+komoot_id+"/profile_embedded";
    const data = await get(url);
    if (data["error"]) {
        return undefined
    }
    return {
        "id": data["username"],
        "name": data["display_name"],
        "avatar": data["avatar"]["src"].replace("width={width}&height={height}&crop={crop}", "/"),
    }
}

export async function getAllTours(komoot_id, tours={}, page = 0) {
    if (komoot_id == "") return undefined
    var url = "https://www.komoot.com/api/v007/users/" + komoot_id + "/tours/?sport_types=&type=tour_recorded&sort_field=date&sort_direction=asc&name=&status=public&hl=fr&limit=200&page=" + page

    const data = await get(url);

    if (data["error"]) {
        // getAllTours("3945589991351")
        return false
    }

    if (!data["_embedded"]) {
        return true
    }

    var tour_page = data["_embedded"]["tours"]
    for (var i = 0; i < tour_page.length; i++) {
        var sport: string = tour_page[i]["sport"]
        if (sport.startsWith("e_")) {
            sport = sport.slice(2, sport.length)
        }

        var date = new Date(tour_page[i]["date"])

        var time_in_motion = tour_page[i]["time_in_motion"]
        if (isNaN(time_in_motion)) time_in_motion = tour_page[i]["duration"]

        var img: string = tour_page[i]["map_image_preview"]["src"]
        img = img.replace("width={width}", "width=200")
        img = img.replace("height={height}", "height=200")
        img = img.replace("crop={crop}", "scale=2")


        tours[date.getTime()] = {
            "id": tour_page[i]["id"],
            "name": tour_page[i]["name"],
            "date": date,
            "distance": tour_page[i]["distance"] / 1000,
            "duration": tour_page[i]["duration"] / 60 / 60,
            "sport": sport,
            "elevation_up": tour_page[i]["elevation_up"],
            "elevation_down": tour_page[i]["elevation_down"],
            "time_in_motion": time_in_motion / 60 / 60,
            "mean_speed": (tour_page[i]["distance"] / 1000) / (time_in_motion / 60 / 60),
            "img": img,
            "lat": tour_page[i]["start_point"]["lat"],
            "lng": tour_page[i]["start_point"]["lng"]
        }
    }

    if (data["_links"]["next"]) {
        await getAllTours(komoot_id, tours, page + 1)
    }
    return true
}



export async function getGPX(id) {
    var url = "https://api.komoot.de/v007/tours/"+id+"/coordinates"
    // cache
    if (id == "" || id== null || id==undefined) return []


    var cache = await getFromIndexedDB(id)
    if (cache) {
        return JSON.parse(cache["value"])
    }
    var data = await get(url);

    if (data["items"]){

        setInIndexedDB(id, JSON.stringify(data["items"]))
        return data["items"]
    }
    return []
}


export var READY = false;
var request = indexedDB.open("komoot", 3);
var db;
const expire_delay = 1000 * 60 * 60 * 24 * 10; // 7 days
request.onerror = function(event) {
    console.log("error: ");
    console.log(event);
}

request.onsuccess = function(event) {
    db = event.target.result;
    setTimeout(() => {
        READY = true;
    }, 100);
}

request.onupgradeneeded = function(event) {
    db = event.target.result;
    var objectStore = db.createObjectStore("tours", { keyPath: "id" });
    objectStore.createIndex("id", "id", { unique: false });


    objectStore = db.createObjectStore("expire", { keyPath: "id" });
    objectStore.createIndex("id", "id", { unique: false });

}


function setInIndexedDB(key, value) {
    var komoot = db.transaction("tours", "readwrite").objectStore("tours");
    komoot.add({ id: key, value: value });
    var expire = db.transaction("expire", "readwrite").objectStore("expire");
    expire.add({ id: key, value: Date.now() + expire_delay });
}

async function getFromIndexedDB(key) {

    var komoot = db.transaction("tours").objectStore("tours");
    var request = komoot.get(key);
    return new Promise((resolve, reject) => {
        request.onsuccess = function(event) {
            var expire = db.transaction("expire", "readwrite").objectStore("expire");
            expire.add({ id: key, value: Date.now() + expire_delay });
            resolve(request.result)
        }
    })
}


export function cleanDB() {

    var komoot = db.transaction("tours").objectStore("tours");
    let jsonDB = {}
    var request = komoot.getAll();
    request.onsuccess = function(event) {
        var all = request.result
        for (var i = 0; i < all.length; i++) {
            jsonDB[all[i].id] = all[i].value
        }

        jsonDB = JSON.stringify(jsonDB)
        // // download
        // var a = document.createElement("a");
        // var file = new Blob([jsonDB], { type: "application/json" });

        // a.href = URL.createObjectURL(file);
        // a.download = "komoot.json";
        // a.click();

    }




    // var date = Date.now()
    // // get all expire
    // var expire = db.transaction("expire").objectStore("expire");
    // var request = expire.getAll();

    // request.onsuccess = function(event) {
    //     var all = request.result
    //     for (var i = 0; i < all.length; i++) {

    //         if (all[i].value < date) {
    //             var komoot = db.transaction("tours", "readwrite").objectStore("tours");
    //             komoot.delete(all[i].id)
    //             var expire = db.transaction("expire", "readwrite").objectStore("expire");
    //             expire.delete(all[i].id)
    //         }
    //     }
    // }
}

export async function getTour(id) {
    var url = "https://api.komoot.de/v007/tours/"+id;
    const data = await get(url);

    if (data["error"]) {
        return undefined
    }

    var img: string = data["map_image"]["src"]
    img = img.replace("/?width={width}&height={height}&crop={crop}", "/?scale=2")



    var response = {
        id: data["id"],
        name: data["name"],
        date: new Date(data["date"]),
        distance: data["distance"] / 1000,
        duration: data["duration"] / 60 / 60,
        sport: data["sport"],
        elevation_up: data["elevation_up"],
        elevation_down: data["elevation_down"],
        time_in_motion: data["time_in_motion"] / 60 / 60,
        mean_speed: (data["distance"] / 1000) / (data["time_in_motion"] / 60 / 60),
        img: img,
        user_id: data["_embedded"]["creator"]["username"],
    }
    return response;
}

